<template>

  <b-card-code
    title="Profile"
  >
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <ValidationObserver v-slot="{handleSubmit}">
  <b-form @submit.prevent="handleSubmit(storeStudentProfile)">
    
    <b-media tag="li">
      <template #aside>
        <b-avatar
          :src="preview"
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <h5 class="mt-0 mb-1">Update Profile Image</h5>
      
      <b-form-file
          ref="refInputEl"
          v-model="user_profile_image"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-card-text>Allowed JPG, JPEG or PNG. Max size of 2MB</b-card-text>
        
    </b-media>
    
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
            label="Nombre"
            label-for="mc-first-name"
          >
            <ValidationProvider rules="required" name="Nombre" v-slot="{ errors }">
              <b-form-input
                v-model = "studentProfile.name"
                id="mc-first-name"
                placeholder="Nombre"
                name="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Apellidos"
            label-for="mc-last-name"
          >
          <ValidationProvider rules="required" name="Apellidos" v-slot="{ errors }">
            <b-form-input
              v-model = "studentProfile.surname"
              id="mc-last-name"
              placeholder="Apellidos"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Telefono"
            label-for="mc-city"
          >
            <ValidationProvider rules="required" name="Telefono" v-slot="{ errors }">
              <b-form-input
                v-model = "studentProfile.telephone_num"
                id="mc-city"
                placeholder="Telefono"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Email"
          >
            <div class="form-label-group">
              <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
                <b-form-input
                  v-model = "studentProfile.email"
                  id="mc-email"
                  type="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Fecha de nacimiento"
            label-for="mc-company"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required|minAge" name="Fecha de nacimiento" v-slot="{ errors }">
              <b-form-datepicker
                id="fecha-de-nacimiento"
                v-model="studentProfile.dob"
                class="mb-1"
                placeholder="Fecha de nacimiento"
                locale='fr'
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
              
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Direccion Completa"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Direccion Completa" v-slot="{ errors }">
              <b-form-input
                id="mc-email"
                v-model="studentProfile.address"
                placeholder="Direccion Completa"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Codigo Postal"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Codigo Postal" v-slot="{ errors }">
              <b-form-input
                v-model="studentProfile.zipcode"
                id="mc-email"
                placeholder="Codigo Postal"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Ciudad"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Ciudad" v-slot="{ errors }">
              <b-form-input
                v-model="studentProfile.city"
                id="mc-email"
                placeholder="Ciudad"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="DNI/NIE o Pasaporte"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="DNI/NIE o Pasaporte" v-slot="{ errors }">
              <b-form-input
                v-model="studentProfile.id_num"
                id="mc-email"
                placeholder="DNI/NIE o Pasaporte"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <!--
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Fecha de expedicion"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Fecha de expedicion" v-slot="{ errors }">
              <b-form-input
                v-model="studentProfile.id_expedition_num"
                id="mc-email"
                placeholder="Fecha de expedicion"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        -->
        <b-col md="6">
          <b-form-group
            label="fecha de expedicion"
            label-for="mc-company"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="fecha de expedicion" v-slot="{ errors }">
              <b-form-datepicker
                id="fecha-de-expedicion"
                v-model="studentProfile.expedition_date"
                class="mb-1"
                placeholder="Fecha de expedicion"
                locale='es'
                :max="max"
                :min="min"
                show-decade-nav
                start-weekday='1'
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
              
            </div>
          </b-form-group>
        </b-col>
        
        <!--<b-col md="6">
        <b-form-group
            label-for="mc-email"
            label="Curso"
        >
        <ValidationProvider rules="required" name="Curso" v-slot="{ errors }">
          <v-select
            v-model="studentProfile.course_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="courses"
            :reduce="course => course.id"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
        </b-form-group>
      </b-col>-->
      
        <!-- submit and reset -->
        <b-col>
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1" 
          >
            Submit
          </b-button>
         
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver> 
    </b-overlay>
   </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BAlert, BCol, BAvatar,BCardText, BOverlay, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ProfileService from '@/services/profile.service.js';
import AuthService from '@/services/auth.service.js';
import CourseService from '@/services/course.service.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BAvatar,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BAlert
  },
  directives: {
    Ripple,
  },
  computed: {
    validationErrors(){
      return this.errors.data
    },
  },
  data() {
    return {
      studentProfile: {
        name: '',
        surname: '',
        email: '',
        telephone_num: '',
        dob: '',
        address: '',
        zip_code: '',
        city: '',
        id_num: '',
        id_expedition_num: '',
        expedition_date: '',
        course_id: 1,
        profileFile: null,
        attachment_url: '',
        
      },
      id: this.$route.params.id,
      getUser: {},
      courses: [],
      logoImg: require('@/assets/images/pages/logo-1.png'),
      avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
      showOverlay: false,
      user_profile_image: null,
      preview: '',
      errors: '',
      base_url: 'https://aula.anclademia.com/public/uploads/public/userProfile/',
      max: moment().format('YYYY-MM-DD'),
      min: moment().subtract(10, 'years').format('YYYY-MM-DD')
      //base_url: 'http://localhost:8000/uploads/public/userProfile/'
    }
  },
  watch:{
    '$route'(to) {
      this.id = to.params.id;
    } 
  },
  methods: {
    setSelected(val) {
      console.log(val)
    },
    dateDisabled(ymd, date) {
      let currentDate = (moment(new Date()).format('YYYY')) - 10 
      let getYear = date.getFullYear();
      return currentDate > getYear;
    },
    inputImageRenderer(event) {
      var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
      reader.readAsDataURL(this.user_profile_image);
    },
    storeStudentProfile() {
          let getData = this.storeFormData();
          this.showOverlay = true;
          ProfileService.editStudentsProfile(getData).then(response => {
                this.showOverlay = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Profile Updated Successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                
            }).catch(error => {
                this.showOverlay = false;
                this.errors = error.response
                //console.log(error)
            });  
    },
    getProfileData()
    {
      this.showOverlay = true;
      AuthService.getCurrentLoggedInUser().then(response => {
        
        var getUserDetail = response.data.data.user.user_detail
        if(getUserDetail) {
          this.studentProfile = getUserDetail;
          //this.studentProfile.attachment_url = getUserDetail.attachment_url;
          //this.preview = 'https://aula.anclademia.com/storage/userProfile/'+getUserDetail.attachment_url;
          this.preview = this.base_url+''+getUserDetail.attachment_url;
        }
        if(this.$store.getters.getUser) {
          this.studentProfile.email = this.$store.getters.getUser.email;
        }
        this.showOverlay = false;
      }).catch(error => {
          this.showOverlay = false;
          this.errors = error.response
      });
    },
    // getCourses()
    // {
    //   CourseService.getAll().then(response => {
    //     this.courses = response.data.data.courses;          
    //   }).catch(error => {
    //     console.log(error)
    //   });
    // },
    storeFormData()
    {
      let formData = new FormData();
      for(let studentKey in this.studentProfile) {
          formData.append(studentKey, this.studentProfile[studentKey]);
      }
      formData.append('user_profile_image', this.user_profile_image);
      return formData;
    }
  },
  created() {
      this.getProfileData();
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
